var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.id)?_c('b-button-group',{staticClass:"mb-1"},[_c('router-link',{attrs:{"to":"/codes-packages/codes","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('a',{attrs:{"href":href},on:{"click":navigate}},[_c('b-button',{staticStyle:{"border-top-left-radius":"0","border-bottom-left-radius":"0"},attrs:{"variant":isActive ? 'primary' : 'outline-primary'}},[_vm._v(" رموز التفعيل ")])],1)]}}],null,false,1749546473)}),_c('router-link',{attrs:{"to":"/codes-packages/packages","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('a',{attrs:{"href":href},on:{"click":navigate}},[_c('b-button',{staticStyle:{"border-top-right-radius":"0","border-bottom-right-radius":"0"},attrs:{"variant":isActive ? 'primary' : 'outline-primary'}},[_vm._v(" الحزم المتوفرة ")])],1)]}}],null,false,2104219804)})],1):_vm._e(),_c('transition',{attrs:{"name":_vm.routerTransition,"mode":"out-in"}},[_c('router-view')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }